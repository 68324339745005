import Header from "../components/Header";
import { ReactComponent as IconError } from "../assets/IconError.svg";

export default function Error(){
  return(
    <div className="wrap done">
      <div className="inner">
        <Header theme='deep' />
        <div className="done-contents">
          <IconError/>
          <p className="done-contents-message">설문에 오류가 있습니다!</p>
          <p className="done-contents-expl">
            고객센터로 문의 부탁드립니다.<br/>
            -<br/>
            <span>문의전화</span><br/>
            02-6204-0351, 0355 <br/>
            (평일 9:00 ~ 18:00 / 주말,공휴일 휴무)
          </p>
        </div>
      </div>
    </div>
  )
}