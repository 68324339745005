import SymbolLogo from "../assets/SymbolLogo.png";
import SymbolLogoD from "../assets/SymbolLogoD.png";

export default function Header(props:{theme:string}){
  return(
    <header className="header">
      <h1>
        {props.theme === 'white'?
          <img src={SymbolLogo} alt="세무법인 프라이어" />
          :
          <img src={SymbolLogoD} alt="세무법인 프라이어" />
        }
      </h1>
      <h2 className={props.theme === 'white'?'theme-white':'theme-deep'}>투자 세액 환급 설문</h2>
    </header>
  )
}