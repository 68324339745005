import { ReactComponent as IconClose } from "../assets/IconClose.svg";

export default function Lagal(props:{fn:any}){
  return(
    <div className="legal">
      <button 
        className="legal-close"
        onClick={()=>{props.fn(false)}}
      ><IconClose/></button>
      <p>지방세법 시행 규칙</p>
    </div>
  )
}