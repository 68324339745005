import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Legal from "../components/Legal";

export default function Survey(){
  const token = new URL(document.location.toString()).searchParams.get('token');
  const navigate = useNavigate();
  const [legalOpen,SetLegalOpen] = useState<boolean>(false);
  const [selectInputs,setSelectInputs] = useState<string>('');
  const [checkedInputs,setCheckedInputs] = useState<string[]>([]);
  const [radioInputs,setRadioInputs] = useState<{buyOrNot:string|null,replaceOrNot:string|null,paperOrNot:string|null}>({
    buyOrNot:null,
    replaceOrNot:null,
    paperOrNot:null,
  });
  const selectRef = useRef<HTMLSelectElement>(null);
  const [thirdOpen,setThirdOpen] = useState<boolean>(false);
  const [businessWhat,setBusinessWhat] = useState<number|any>(0);
  const businessCode = ['업종을 선택하세요','일반 업종','운수업','어업','건설업','도매/소매/물류산업','관광숙박업/국제회의기획업','전문휴양업']
  const businessGuide = ['업종을 선택하세요','기계장치','차량, 운반구, 선박 등(자가용 제외)','선박','건설 기계','창고 및 설비','승강기 등 건설 설비','숙박시설, 전문휴양시설']

  // 업종 설비 법률 팝업
  const legalOpenFn = ()=>{
    SetLegalOpen(true);
  }
  const legalCloseFn = (x:any)=>{
    SetLegalOpen(x);
  }

  // 업종 선택
  const businessHandler = (e:React.ChangeEvent<HTMLSelectElement>)=>{
    if(e.target.value === '업종을 선택하세요'){
      setSelectInputs('미선택')
    }else{
      setSelectInputs(e.target.value)
    }
    setBusinessWhat(selectRef.current?.selectedIndex)
  }

  // 공통 질문 값
  const checkHandler = (checked:boolean,value:string)=>{
    if(checked){
      setCheckedInputs([...checkedInputs,value])
    }else{
      setCheckedInputs(checkedInputs.filter((el)=>el !== value))
    }
  }
  
  // 버튼 질문 값
  const radioHandler = (name:string,value:string)=>{
    if(name === 'invest-what' && value === 'true'){
      setRadioInputs({...radioInputs,buyOrNot:'예'});
    }else if(name === 'invest-what' && value === 'false'){
      setRadioInputs({...radioInputs,buyOrNot:'아니오'});
      if(!thirdOpen){
        setRadioInputs({...radioInputs,buyOrNot:'아니오', replaceOrNot:null, paperOrNot:null});
      }
    }else if(name === 'invest-what' && value === 'dont-know'){
      setRadioInputs({...radioInputs,buyOrNot:'모름'});
      if(!thirdOpen){
        setRadioInputs({...radioInputs,buyOrNot:'아니오', replaceOrNot:null, paperOrNot:null});
      }
    }else if(name === 'invest-replace' && value === 'true'){
      setRadioInputs({...radioInputs,replaceOrNot : '예'});
    }else if(name === 'invest-replace' && value === 'false'){
      setRadioInputs({...radioInputs,replaceOrNot : '아니오', paperOrNot:null});
    }else if(name === 'invest-replace' && value === 'dont-know'){
      setRadioInputs({...radioInputs,replaceOrNot : '모름', paperOrNot:null});
    }else if(name === 'invest-paper' && value === 'true'){
      setRadioInputs({...radioInputs,paperOrNot : '예'});
    }else if(name === 'invest-paper' && value === 'false'){
      setRadioInputs({...radioInputs,paperOrNot : '아니오'});
    }else if(name === 'invest-paper' && value === 'dont-know'){
      setRadioInputs({...radioInputs,paperOrNot : '모름'});
    }
  }

  // 공통 질문 '사업용 유형 자산' 항목 선택 시 질문 조건 처리
  const firstAnswerFn = (e:any)=>{
    if(e.currentTarget.checked){
      setThirdOpen(true)
    }else if(!e.currentTarget.checked){
      setThirdOpen(false)
      if(radioInputs.buyOrNot === '아니오' || radioInputs.buyOrNot === '모름'){
        setRadioInputs({...radioInputs,replaceOrNot : null, paperOrNot:null});
      }
    }
  }

  // 제출
  const formSubmitFn = (e:any)=>{
    e.preventDefault();

    fetch(`https://getdata.tax-back.co.kr/`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json;",
      },
      body: JSON.stringify({
        mode:'res2',
        token:token,
        answer:{
          '(0) 업종':selectInputs,
          '(1) 구매품목':checkedInputs,
          '(2) 특수업종 물품 구매여부':radioInputs.buyOrNot,
          '(3) 대체 구매 여부':radioInputs.replaceOrNot,
          '(4) 폐기증서 여부':radioInputs.paperOrNot,
        }
      })
    })
    .then((result)=>{
      navigate('/done');
    })
    .catch((err)=>{
      console.log(err);
      navigate('/error')
    });
  }

  return(
    <div className="wrap survey">
      <div className="survey-top">
        <div className="inner">
          <Header theme='white'/>
          <div className="survey-intro">
            <p className="survey-intro-advise">
              정확한 환급액 검토를 위해 <br />아래 설문에 <span>아는대로</span> 대답해주세요.
            </p>
            <p className="survey-intro-advise-sub">
            모르실 경우 입력하지 않으셔도 되지만 <br />환급액 검토 시 제약이 있을 수 있습니다.
            </p>
          </div>
        </div>
      </div>
      <form 
        action="get" 
        onSubmit={formSubmitFn}
        className="survey-form"
      >
        <div className="inner">
          {legalOpen && <Legal fn={legalCloseFn}/> }
          <div className="survey-question">
            <p className="survey-question-title">사업장의 업종을 선택해주세요.</p>
            <select 
              className="survey-select"
              name="business-what"
              onChange={businessHandler}
              ref={selectRef}
            >
              {businessCode.map((code,index)=>{
                return(
                  <option value={code} key={index}>{code}</option>
                )
              })}
            </select>
          </div>
          <div className="survey-question">
            <p className="survey-question-title">
              (공통) 하기 목록 중 직전 연도로부터 5년간 구매하신 적이 있는 것을 모두 표시해주세요.
            </p>
            <p className="survey-question-guide">
              구매하신 내역 중 중고품, 임대품, 월간 결제로 이용되는 내역은 제외됩니다. <br />
              (할부로 구매하신 내역은 가능합니다.)
            </p>
            <label className="survey-checkbox">
              <input 
                type="checkbox" 
                name="invest-thing" 
                value='사업용 유형 자산'
                onChange={(e)=>{checkHandler(e.currentTarget.checked,e.currentTarget.value)}}
                onClick={firstAnswerFn}
              />
              <span className="invest-check"></span>
              사업용 유형 자산 (차량, 선박, 건물, 비품, 중고품 제외)
            </label>
            <label className="survey-checkbox">
              <input 
                type="checkbox" 
                name="invest-thing" 
                value='소프트웨어' 
                onChange={(e)=>{checkHandler(e.currentTarget.checked,e.currentTarget.value)}}
              />
              <span className="invest-check"></span>
              사업에 필요한 소프트웨어
            </label>
            <label className="survey-checkbox">
              <input 
                type="checkbox" 
                name="invest-thing" 
                value='특허권,실용신안권,디자인권' 
                onChange={(e)=>{checkHandler(e.currentTarget.checked,e.currentTarget.value)}}
              />
              <span className="invest-check"></span>
              특허권, 실용신안권, 디자인권
            </label>
          </div>
          <div className="survey-question">
            <p className="survey-question-title">
              하기 항목에 대하여 직전 연도로 부터 5년 내 구매하신 적이 있나요?
            </p>
            <p className="survey-question-guide">
              {businessGuide[businessWhat]}
              {/* 업종별 도움말 팝업 */}
              {/* <span 
                className="survey-question-guide-mark"
                onClick={legalOpenFn}
              >?</span> */}
            </p>
            <div className="survey-radios">
              <label className="survey-radio">
                <input 
                  type="radio" 
                  name="invest-what"
                  value="true"
                  onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                />
                <span>예</span>
              </label>
              <label className="survey-radio">
                <input 
                  type="radio" 
                  name="invest-what"
                  value="false"
                  onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                />
                <span>아니오</span>
              </label>
              <label className="survey-radio">
                <input 
                  type="radio" 
                  name="invest-what"
                  value="dont-know"
                  onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                />
                <span>모름</span>
              </label>
            </div>
          </div>
          {thirdOpen || radioInputs.buyOrNot === '예' ?
            <div className="survey-question">
              <p className="survey-question-title">
                구매하신 자산은 기존 사용하던 자산을 폐기하고 이를 대체하기 위해 구매하신 건가요?
              </p>
              <div className="survey-radios">
                <label className="survey-radio">
                  <input 
                    type="radio" 
                    name="invest-replace"
                    value="true"
                    onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                  />
                  <span>예</span>
                </label>
                <label className="survey-radio">
                  <input 
                    type="radio" 
                    name="invest-replace"
                    value="false"
                    onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                  />
                  <span>아니오</span>
                </label>
                <label className="survey-radio">
                  <input 
                    type="radio" 
                    name="invest-replace"
                    value="dont-know"
                    onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                  />
                  <span>모름</span>
                </label>
              </div>
            </div>
            :``
          }
          {(thirdOpen || radioInputs.buyOrNot==='예') && radioInputs.replaceOrNot==='예' ?
            <div className="survey-question">
              <p className="survey-question-title">
                위 항목에서 구매한 자산 관련하여 대체확인증서 혹은 기존 사용하던 물품의 폐기증서를 보관하고 계신가요?
              </p>
              <div className="survey-radios">
                <label className="survey-radio">
                  <input 
                    type="radio" 
                    name="invest-paper"
                    value="true"
                    onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                  />
                  <span>예</span>
                </label>
                <label className="survey-radio">
                  <input 
                    type="radio" 
                    name="invest-paper"
                    value="false"
                    onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                  />
                  <span>아니오</span>
                </label>
                <label className="survey-radio">
                  <input 
                    type="radio" 
                    name="invest-paper"
                    value="dont-know"
                    onChange={(e)=>{radioHandler(e.currentTarget.name,e.currentTarget.value)}}
                  />
                  <span>모름</span>
                </label>
              </div>
            </div>
            :``
          }
          <button 
            type="submit"
            className={
              businessWhat === 0 ||
              checkedInputs.length === 0 || 
              radioInputs.buyOrNot === null || 
              ((thirdOpen || radioInputs.buyOrNot==='예') && radioInputs.replaceOrNot === null) ||
              (radioInputs.replaceOrNot==='예' && radioInputs.paperOrNot === null)
              ? "survey-submit" : "survey-submit survey-submit-on"
            }
            disabled={
              businessWhat === 0 ||
              checkedInputs.length === 0 || 
              radioInputs.buyOrNot === null || 
              ((thirdOpen || radioInputs.buyOrNot==='예') && radioInputs.replaceOrNot === null) ||
              (radioInputs.replaceOrNot==='예' && radioInputs.paperOrNot === null)
              ? true : false
            }
          >제출</button>
        </div>
      </form>
    </div>
  )
}