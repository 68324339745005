import { useEffect } from "react";
import Header from "../components/Header";
import { ReactComponent as IconDone } from "../assets/IconDone.svg";

export default function Done(){
  useEffect(()=>{
    window.history.pushState(null,document.title,window.location.href);
    window.addEventListener('popstate',()=>{
      window.history.pushState(null,document.title,window.location.href);
    })
    window.addEventListener('beforeunload',(e)=>{
      e.preventDefault();
      e.returnValue='';
    })
    return ()=>window.removeEventListener('beforeunload',(e)=>{
      e.preventDefault();
      e.returnValue='';
    })
  },[])
  return(
    <div className="wrap done">
      <div className="inner">
        <Header theme='deep' />
        <div className="done-contents">
          <IconDone/>
          <p className="done-contents-message">
            설문 제출이 완료되었습니다!
          </p>
          <p className="done-contents-expl">
            본 설문은 <span>투자세액공제</span> 관련 항목으로 <br />
            고객님의 최대 환급금을 <br />
            찾아드리기 위해 진행되었습니다. <br />
            -<br />
            주신 결과를 바탕으로 <br />추가 서류 요청이 있을 수 있으며 <br />
            검토 결과는 <span>환급금에 반영</span>되어 안내될 예정입니다.
          </p>
        </div>
      </div>
    </div>
  )
}