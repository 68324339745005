import { useEffect } from "react";
import { Reset } from "styled-reset";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Survey from "./pages/Survey";
import Done from "./pages/Done";
import Error from "./pages/Error";

function App() {
  function setScreenSize(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh",`${vh}px`);
  }
  window.addEventListener('resize',()=>setScreenSize());
  useEffect(()=>{
    setScreenSize()
  },[])

  return (
    <BrowserRouter>
      <div className="App">
        <Reset />
        <Routes>
          <Route path='/' element={<Survey />} />
          <Route path='/done' element={<Done />} />
          <Route path='/error' element={<Error />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
